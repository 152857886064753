async function postTerminalAPI(data) {
  const result = await window.fetch('/webapi/timeslots/api/v1/terminal', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
}

export default postTerminalAPI;
