import styles from './styles.module.css';
import './styles.scss';
import { useEffect, useState } from 'react';
import cn from 'classnames';

const Star = ({ className = '' }) => (
  <svg
    className={className}
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9471 29.6226L7.32588 35.6538L9.54534 22.8794L0.143555 13.8325L13.1365 11.9687L18.9471 0.34613L24.7577 11.9687L37.7507 13.8325L28.3489 22.8794L30.5684 35.6538L18.9471 29.6226Z"
      fill="#B8B8B8"
    />
  </svg>
);

const badges = [
  'Ответственный',
  'Всегда на связи',
  'Без опозданий',
  'Груз в сохранности',
  'Чистая машина',
];

const App = () => {
  const [score, setScore] = useState(0);
  const [selectedBadges, selectBadges] = useState([]);

  const handleScore = (newScore) => (e) => {
    e.stopPropagation();
    setScore(newScore);
  };

  const handleSelectBadge = (badge) => () => {
    selectBadges((prevState) => {
      if (prevState.includes(badge)) {
        return prevState.filter((el) => el !== badge);
      }
      return [...prevState, badge];
    });
  };

  return (
    <div>
      <div className="flex items-center">
        <span className={styles.h3}>Оцените перевозку</span>
        <div>
          <span className="glz-badge glz-is-green ml-2">NEW</span>
        </div>
      </div>
      <div
        className="glz-shape glz-shape-with-shadow glz-has-background-white"
        style={{ padding: '15px' }}
      >
        <div style={{ fontSize: '12px' }}>
          Ваша оценка влияет на Рейтинг участников и делает его более точным и объективным.
        </div>
        <div className={cn('main-score-container', `score-${score}`)}>
          <div className="mt-2">
            <div
              className={cn('stars-container flex', score >= 1 && 'active')}
              onClick={handleScore(1)}
            >
              <Star className="star" />
              <div
                className={cn('stars-container flex', score >= 2 && 'active')}
                onClick={handleScore(2)}
              >
                <Star className="star" />
                <div
                  className={cn('stars-container flex', score >= 3 && 'active')}
                  onClick={handleScore(3)}
                >
                  <Star className="star" />
                  <div
                    className={cn('stars-container flex', score >= 4 && 'active')}
                    onClick={handleScore(4)}
                  >
                    <Star className="star" />
                    <div
                      className={cn('stars-container flex', score >= 5 && 'active')}
                      onClick={handleScore(5)}
                    >
                      <Star className="star" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-3 cursor-pointer">
            {badges.map((badge) => (
              <span
                className={cn(
                  'glz-badge mr-3',
                  selectedBadges.includes(badge) ? 'glz-is-green' : 'glz-is-light-gray',
                )}
                onClick={handleSelectBadge(badge)}
              >
                {badge}
              </span>
            ))}
          </div>
        </div>

        <textarea className="glz-textarea mt-3" placeholder="Дополните оценку"></textarea>
        <div>
          <a className="glz-link glz-is-medium glz-is-gray glz-is-underline mr-3">
            Добавить рекомендацию
          </a>
          <a className="glz-link glz-is-medium glz-is-gray glz-is-underline">
            Подробнее об оценке перевозок
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
