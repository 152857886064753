import { makeAutoObservable, autorun } from 'mobx';
import AsyncStateModel from '../models/AsyncStateModel';
import postChangeTimeslotStatusAPI from '../services/postChangeTimeslotStatusAPI';
import responseMiddleware from '../middlewares/responseMiddleware';

class StayingStatusesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  statusOfChangingTimeslotStatus = new AsyncStateModel();

  changeTimeslotStatus = async (
    data,
    successCallback = function () {},
    errorCallback = function () {},
  ) => {
    this.statusOfChangingTimeslotStatus.request();
    return postChangeTimeslotStatusAPI(data)
      .then(responseMiddleware)
      .then((res) => {
        this.statusOfChangingTimeslotStatus.success();
        this.rootStore.notificationStore.pushNotification('Статус успешно изменён', 'success');
        successCallback();
      })
      .catch((error) => {
        this.statusOfChangingTimeslotStatus.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при изменении статуса',
          'error',
        );
        errorCallback();
        console.log(error);
      });
  };
}

export default StayingStatusesStore;
