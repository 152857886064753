async function postStatisticsAPI(statistics) {
  const result = await window.fetch(
    `/webapi/timeslots/api/v1/staying_statuses/warehouse/statistics`,
    {
      method: 'POST',
      body: JSON.stringify(statistics),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result;
}

export default postStatisticsAPI;
