import { autorun, makeAutoObservable } from 'mobx';
import postWindowAPI from '../services/postWindowAPI';
import responseMiddleware from '../middlewares/responseMiddleware';
import AsyncStateModel from '../models/AsyncStateModel';
import format from 'date-fns/format';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import last from 'lodash/last';

const actionTypes = {
  loading: 'Загрузка',
  unloading: 'Разгрузка',
  loading_unloading: 'Загрузка и разгрузка',
};

const approveStatuses = {
  approved: 'Бронирование подтверждено',
  decline: 'Бронирование отклонено',
  await_reaction: 'Ожидает подтверждения',
  await_client: 'Ожидает подтверждения от владельца брони',
};

const fieldsForRemoving = ['action', 'bounds', 'box', 'resourceId', 'slots', 'start', 'end'];

class TimeslotPopupStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {
      if (this.timeslotPopupData.staying_statuses_history) {
        this.lastStatus = this.timeslotPopupData.staying_statuses_history.at(-1);
      }
    });
  }

  popupModes = {
    VIEW: 'timeslotView',
    ADD: 'timeslotAdd',
    EDIT: 'timeslotEdit',
    EDIT_STATUS: 'timeslotEditStatus',
  };

  isShowPopup = false;
  defaultPopupMode = this.popupModes.VIEW;
  popupMode = this.defaultPopupMode;

  timeslotView = {};
  timeslotAdd = {};
  timeslotEdit = {};
  timeslotEditStatus = {};

  lastStatus = {};

  statusSaveBooking = new AsyncStateModel();
  statusDeleteBooking = new AsyncStateModel();
  navigateDay = new Date().getDay();

  get timeslotPopupData() {
    return this[this.popupMode];
  }

  get gates() {
    if (this.timeslotPopupData.terminal) {
      const terminal = this.rootStore.terminalsStore.activeWarehouseTerminals.find(
        (terminal) => String(terminal.id) === String(this.timeslotPopupData.terminal),
      );
      return terminal.gates_count || 0;
    }
    return 0;
  }

  get isAddMode() {
    return this.popupMode === this.popupModes.ADD;
  }
  get isEditMode() {
    return this.popupMode === this.popupModes.EDIT;
  }
  get isViewMode() {
    return this.popupMode === this.popupModes.VIEW;
  }

  get actionName() {
    return actionTypes[this.timeslotPopupData.action_type] || '';
  }

  get approveStatusName() {
    return approveStatuses[this.timeslotPopupData.approve_status] || '';
  }

  get minTimeWeek() {
    const time = this.rootStore.warehouse.normalizedSchedule.map((item) =>
      Number(first(item?.time_from.split(':'))),
    );
    return Math.min(...time);
  }

  get maxTimeWeek() {
    const time = this.rootStore.warehouse.normalizedSchedule.map((item) =>
      Number(first(item?.time_to.split(':'))),
    );
    return Math.max(...time);
  }

  get allWeekTime() {
    const time = this.rootStore.warehouse.normalizedSchedule.map((item) =>
      item?.time_to.split(':').join(''),
    );
    return time;
  }

  get daysOfWeekSchedules() {
    const currentDay = {};
    this.rootStore.warehouse.normalizedSchedule.forEach((item) => {
      currentDay[item.day_of_week] = {
        timeFromHours: first(item.time_from.split(':')),
        timeFromMinutes: last(item.time_from.split(':')),
        timeToHours: first(item.time_to.split(':')),
        timeToMinutes: last(item.time_to.split(':')),
      };
    });

    return currentDay;
  }

  getNavigateDay = (day = new Date()) => {
    this.navigateDay = day === 0 ? 7 : day;
  };

  openPopupView = (timeslot) => {
    this.timeslotView = timeslot;
    this.popupMode = this.popupModes.VIEW;
    this.isShowPopup = true;
  };

  openPopupViewById = (id) => {
    const timeslot = this.rootStore.scheduleStore.filteredWindows.find((slot) => slot.id === id);
    this.openPopupView(timeslot);
  };

  openPopupEditById = (id) => {
    const timeslot = this.rootStore.scheduleStore.filteredWindows.find((slot) => slot.id === id);
    timeslot.start = new Date(`${timeslot.date_from}T${timeslot.time_from}`);
    timeslot.end = new Date(`${timeslot.date_to}T${timeslot.time_to}`);
    this.openPopupEdit(timeslot);
  };

  openPopupAdd = (timeslot) => {
    this.timeslotAdd = timeslot;
    this.popupMode = this.popupModes.ADD;
    this.isShowPopup = true;
  };

  openPopupEdit = (timeslot) => {
    this.timeslotEdit = timeslot;
    this.popupMode = this.popupModes.EDIT;
    this.isShowPopup = true;
  };

  openPopupEditStatus = (statusData) => {
    this.isShowPopup = false;
    this.timeslotEditStatus = statusData;
    this.popupMode = this.popupModes.EDIT_STATUS;
    this.isShowPopup = true;
  };

  changePopupMode = (popupMode) => {
    this.isShowPopup = false;
    this.popupMode = popupMode;
    this.isShowPopup = true;
  };

  onChangeFieldTimeslot = (value, field) => {
    this[this.popupMode][field] = value;
  };

  onClose = () => {
    this.isShowPopup = false;
    this[this.popupMode] = {};
    this.popupMode = this.defaultPopupMode;
  };

  onSelectTerminal = (terminal) => {
    const isSameTerminal = this.timeslotPopupData.terminal === terminal;
    this[this.popupMode].terminal = Number(terminal);
    if (!isSameTerminal) {
      delete this[this.popupMode].gate_number;
    }
  };

  onSelectGate = (gate) => {
    this[this.popupMode].gate_number = Number(gate);
  };

  onSelectActionType = (action_type) => {
    this[this.popupMode].action_type = action_type;
  };

  // params = { warehouse: 'Обязательное поле' }
  saveBooking = async (params = {}, successCallback = function () {}) => {
    this.statusSaveBooking.request();
    let data = cloneDeep(this.timeslotPopupData);
    data.date_from = format(data.start, 'yyyy-MM-dd');
    data.date_to = format(data.end, 'yyyy-MM-dd');
    data.time_from = format(data.start, 'HH:mm');
    data.time_to = format(data.end, 'HH:mm');
    data = { ...data, ...params };

    return postWindowAPI({
      timeslot: omit(data, fieldsForRemoving),
    })
      .then(responseMiddleware)
      .then((res) => {
        this.statusSaveBooking.success();
        this.rootStore.notificationStore.pushNotification(
          'Бронирование успешно сохранено',
          'success',
        );
        successCallback();
      })
      .catch((error) => {
        this.statusSaveBooking.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при сохранении бронирования',
          'error',
        );
        console.log(error);
      });
  };

  deleteBooking = async (successCallback = function () {}) => {
    this.statusDeleteBooking.request();
    const data = cloneDeep(this.timeslotPopupData);
    return postWindowAPI({
      timeslot: {
        ...pick(data, ['id', 'approve_status', 'warehouse']),
        deleted: true,
      },
    })
      .then(responseMiddleware)
      .then((res) => {
        this.statusDeleteBooking.success();
        this.rootStore.notificationStore.pushNotification(
          'Бронирование успешно удалено',
          'success',
        );
        successCallback();
      })
      .catch((error) => {
        this.statusDeleteBooking.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при удалении бронирования',
          'error',
        );
        console.log(error);
      });
  };
}

export default TimeslotPopupStore;
