async function deleteWarehouseAPI(id) {
  const result = await window.fetch('/webapi/timeslots/api/v1/warehouse', {
    method: 'DELETE',
    body: JSON.stringify(id),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
}

export default deleteWarehouseAPI;
