import { makeAutoObservable } from 'mobx';

class PopupsStore {
  constructor() {
    makeAutoObservable(this);
  }

  isShowBoard = false;
  isShowGate = false;

  onHandleOpen = () => {
    this.isShowBoard = true;
  };

  onHandleClose = () => {
    this.isShowBoard = false;
  };
}

export default PopupsStore;
