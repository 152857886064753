import { makeAutoObservable, runInAction } from 'mobx';
import getWindowsAPI from '../services/getWindowsAPI';
import postWindowAPI from '../services/postWindowAPI';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';

const filterFields = {
  terminal: 'terminalUserFilers',
  gate: 'gateUserFilers',
};

class ScheduleStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  filteredWindows = [];
  allWindows = [];
  atiID = window?.atiUser?.profile?.ati_code || '';
  isSchedulePage = false;

  terminalUserFilers = [];
  gateUserFilers = [];

  getAllWindowsState = new AsyncStateModel();
  getFilteredWindowsState = new AsyncStateModel();

  get countIncomingWindowsForActiveWarehouse() {
    return this.allWindows.filter(
      (slot) =>
        String(slot.warehouse) === String(this.rootStore.warehouse.activeWarehouseID) &&
        slot.approve_status === 'await_reaction',
    ).length;
  }

  get maxGatesCountForFilteredTerminal() {
    let terminals = this.rootStore.terminalsStore.activeWarehouseTerminals;
    if (!isEmpty(this.terminalUserFilers)) {
      terminals = terminals.filter((terminal) => this.terminalUserFilers.includes(terminal.id));
    }
    const gates = terminals.map((terminal) => terminal.gates_count);
    return max(gates);
  }

  get filteredWindowsByTerminal() {
    const isAllTerminals =
      this.terminalUserFilers.length ===
      this.rootStore.terminalsStore.activeWarehouseTerminals.length;
    if (isEmpty(this.terminalUserFilers) || isAllTerminals) return this.filteredWindows;
    return this.filteredWindows.filter((el) => this.terminalUserFilers.includes(el.terminal));
  }

  get filteredWindowsByGate() {
    const isAllGates = this.maxGatesCountForFilteredTerminal === this.gateUserFilers.length;
    if (isEmpty(this.gateUserFilers) || isAllGates) return this.filteredWindowsByTerminal;
    return this.filteredWindowsByTerminal.filter((el) =>
      this.gateUserFilers.includes(el.gate_number),
    );
  }

  get filteredWindowsByUser() {
    return this.filteredWindowsByGate;
  }

  toggleUserFilter = (id, filterName = '', checked) => {
    const filterField = filterFields[filterName];
    if (!filterField) return;
    if (!checked) {
      this[filterField] = this[filterField].filter((_id) => _id !== id);
    } else {
      this[filterField] = Array.from(new Set([...this[filterField], id]));
    }
  };

  resetTerminalUserFilers = () => {
    this.terminalUserFilers = [];
  };

  resetGateUserFilers = () => {
    this.gateUserFilers = [];
  };

  resetAllUserFilers = () => {
    this.resetTerminalUserFilers();
    this.resetGateUserFilers();
  };

  setSchedulePage = (value) => {
    this.isSchedulePage = value;
  };

  resetFilteredWindows = () => {
    this.filteredWindows = [];
  };

  getAllWindows = async () => {
    this.getAllWindowsState.request();
    await getWindowsAPI(this.atiID)
      .then((res) => res.json())
      .then((data) => {
        runInAction(() => {
          this.allWindows = data.result.time_slots;
          this.getAllWindowsState.success();
        });
      })
      .catch((e) => {
        console.log(e);
        this.getAllWindowsState.failure();
      });
  };

  getFilteredWindows = async () => {
    this.resetFilteredWindows();
    this.getFilteredWindowsState.request();
    await getWindowsAPI(this.atiID, window.location.search)
      .then((res) => res.json())
      .then((data) => {
        // Контракт изменился, переписывать в других местах влом, поэтому так
        // this.filteredWindows = data.result.time_slots.map((item) => ({
        //   ...item,
        //   time_range_from:
        //     item.date_from && item.time_from ? [item.date_from, item.time_from].join('T') : '',
        //   time_range_to: item.date_to && item.time_to ? [item.date_to, item.time_to].join('T') : '',
        // }));
        runInAction(() => {
          const notDeletedWindows = data.result.time_slots?.filter((el) => !el.deleted);
          this.filteredWindows = notDeletedWindows;
          this.getFilteredWindowsState.success();
        });
      })
      .catch((e) => {
        console.log(e);
        this.getFilteredWindowsState.failure();
      });
  };

  approveWindow = async (window) => {
    await postWindowAPI({ timeslot: { ...window, approve_status: 'approved' } }, this.atiID)
      .then(responseMiddleware)
      .catch((e) => {
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при подтверждении бронирования',
          'error',
        );
        console.log(e);
      });
  };

  declineWindow = async (window) => {
    await postWindowAPI({ timeslot: { ...window, approve_status: 'decline' } }, this.atiID)
      .then(responseMiddleware)
      .catch((e) => {
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при отмене бронирования',
          'error',
        );
        console.log(e);
      });
  };
}

export default ScheduleStore;
