import { makeAutoObservable, reaction, runInAction } from 'mobx';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';
import format from 'date-fns/format';
import postAvailabilityAPI from '../services/postAvailabilityAPI';
import postWindowAPI from '../services/postWindowAPI';
import postWindowPublicAPI from '../services/postWindowPublicAPI';

class BookingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    reaction(
      () => this.isSavedLocalContacts,
      () => {
        this.getLocalContacts();
      },
    );
  }

  date = null;
  time = {};
  availableDays = [];
  availableSlots = [];

  localName = localStorage.getItem('ts_name') || '';
  localPhone = localStorage.getItem('ts_phone') || '';
  localCountryId = localStorage.getItem('ts_country') || '0';
  localEmail = localStorage.getItem('ts_email') || '';
  isSavedLocalContacts = false;
  isNotRegistered = false;

  statusGetAvailabilityDays = new AsyncStateModel();
  statusGetAvailabilityTime = new AsyncStateModel();
  statusBookingWindow = new AsyncStateModel();

  onChangeIsNotRegistered = (value) => {
    this.isNotRegistered = value;
  };

  goToEditLocalContacts = () => {
    this.isSavedLocalContacts = false;
  };

  saveLocalContacts = (name, phone, email, countryId) => {
    localStorage.setItem('ts_name', name);
    localStorage.setItem('ts_phone', phone);
    localStorage.setItem('ts_country', countryId);
    localStorage.setItem('ts_email', email);
    this.isSavedLocalContacts = true;
  };

  resetSelectedValues = () => {
    this.date = null;
    this.time = {};
  };

  resetAvailableSlots = () => {
    this.availableSlots = [];
  };

  setDate = (value) => {
    this.date = value;
  };

  setTime = (value) => {
    this.time = value;
  };

  get isLocalUser() {
    return !!this.localName && !!this.localPhone;
  }

  getLocalContacts = () => {
    this.localName = localStorage.getItem('ts_name') || '';
    this.localCountryId = localStorage.getItem('ts_country') || '0';
    this.localPhone = localStorage.getItem('ts_phone') || '';
    this.localEmail = localStorage.getItem('ts_email') || '';
  };

  get stringDate() {
    return this.date ? format(new Date(this.date), 'yyyy-MM-dd') : '';
  }

  getAvailabilityDays = (warehouse) => {
    this.statusGetAvailabilityDays.request();
    postAvailabilityAPI({ warehouse })
      .then(responseMiddleware)
      .then((res) => {
        runInAction(() => {
          this.availableDays = res.result.available_days;
          this.statusGetAvailabilityDays.success();
        });
      })
      .catch((error) => {
        this.statusGetAvailabilityDays.failure();
        console.log(error);
      });
  };

  getAvailabilityTime = (warehouse, desired_date) => {
    this.statusGetAvailabilityTime.request();
    postAvailabilityAPI({ warehouse, desired_date })
      .then(responseMiddleware)
      .then((res) => {
        runInAction(() => {
          this.availableSlots = res.result.available_slots;
          this.statusGetAvailabilityTime.success();
        });
      })
      .catch((error) => {
        this.statusGetAvailabilityTime.failure();
        console.log(error);
      });
  };

  bookWindow = (warehouse) => {
    this.statusBookingWindow.request();
    const date = this.stringDate;
    const time = this.time;
    if (time.time_to === '00:00') {
      time.time_to = '23:59';
    }

    const contact = this.rootStore.profileStore.contact;
    const isUser = this.rootStore.profileStore.isUser;
    const name = isUser ? contact.name || '' : this.localName;
    const phone = isUser ? contact.phone || contact.mobile || '' : this.localPhone;
    const email = isUser ? '' : this.localEmail;

    const emailObj = this.rootStore.profileStore.isUser ? {} : { email };
    const contryIdObj = this.rootStore.profileStore.isUser
      ? {}
      : { country_phone_id: this.localCountryId };
    const postWindow = this.rootStore.profileStore.isUser ? postWindowAPI : postWindowPublicAPI;

    postWindow({
      timeslot: {
        date_from: date,
        date_to: date,
        time_from: time.time_from.match(/\d\d:\d\d/)[0],
        time_to: time.time_to.match(/\d\d:\d\d/)[0],
        approve_status: 'await_reaction',
        warehouse,
        contact_phone: phone,
        contact_name: name,
        ...emailObj,
        ...contryIdObj,
      },
    })
      .then(responseMiddleware)
      .then((res) => {
        this.statusBookingWindow.success();
        this.rootStore.notificationStore.pushNotification(
          'Запрос на бронирование успешно отправлен',
          'success',
        );
      })
      .catch((error) => {
        this.statusBookingWindow.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при бронировании',
          'error',
        );
        console.log(error);
      });
  };
}

export default BookingStore;
