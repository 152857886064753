const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

async function postWindowPublicAPI(data) {
  const body = JSON.stringify(data);

  return await window
    .fetch('/webapi/public/timeslots/public/api/v1/timeslots', { ...requestOptions, body })
    .catch((error) => console.log('error', error));
}

export default postWindowPublicAPI;
