async function getWarehousesAPI() {
  const result = await window.fetch('/webapi/timeslots/api/v1/warehouse', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
}

export default getWarehousesAPI;
