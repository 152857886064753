import { makeAutoObservable, autorun, runInAction } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import getWarehousesAPI from '../services/getWarehousesAPI';
import postWarehouseAPI from '../services/postWarehouseAPI';
import deleteWarehouseAPI from '../services/deleteWarehouse';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';
import getBasePath from '../utils/getBasePath';

const initStateSchedule = [
  ...Array(7)
    .fill()
    .map((_x, index) => ({
      time_from: '',
      time_to: '',
      day_of_week: index + 1,
      checked: false,
      isOrderError: false,
    })),
];

const defaultWarehouse = {
  name: '',
  address: '',
  city_id: '',
  schedule: initStateSchedule,
  contacts: [
    {
      name: '',
      phone: '',
      country_phone_id: '0',
    },
  ],
};

class WarehouseStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {
      if (this.activeWarehouse.schedule) {
        this.getUpdatedSchedules();
      }
    });
  }

  warehouses = [];
  terminals = [];
  isWarehousePage = false;
  isOrderError = false;

  activeWarehouseID = 0;
  activeWarehouse = defaultWarehouse;
  suggestions = [];

  statusGetWarehouses = new AsyncStateModel();
  statusDeleteWarehouse = new AsyncStateModel();
  statusSaveWarehouse = new AsyncStateModel();

  get bookingLink() {
    return this.activeWarehouseID
      ? `https://${getBasePath()}/booking/${this.activeWarehouseID}`
      : '';
  }

  get displayTimeFrom() {
    return this.activeWarehouse?.schedule[0]?.time_from || '';
  }

  get displayTimeTo() {
    return this.activeWarehouse?.schedule[0]?.time_to || '';
  }

  get isTwentyFourHours() {
    return (
      (this.activeWarehouse?.schedule[0]?.time_from?.startsWith('00:00') &&
        this.activeWarehouse?.schedule[0]?.time_to?.startsWith('23:59')) ||
      false
    );
  }

  isSelectedDay = (dayOfWeek) => {
    return !!this.activeWarehouse?.schedule?.find(
      (day) => String(day.day_of_week) === String(dayOfWeek),
    );
  };

  getUpdatedSchedules = () => {
    const result = [];
    const key = 'day_of_week';
    initStateSchedule.forEach((el) => {
      const found = this.activeWarehouse.schedule.find((item) => Number(item[key]) === el[key]);
      if (found) {
        el = Object.assign(found);
      }
      result.push(el);
    });

    this.activeWarehouse.schedule = result;
  };

  onHandleTimeInputChange = (e) => {
    if (['time_from', 'time_to'].includes(e.target.name)) {
      const schedules = [...this.activeWarehouse.schedule];
      schedules[e.target.id - 1][e.target.name] = e.target.value;
      this.activeWarehouse.schedule = Object.assign(this.activeWarehouse.schedule, schedules);
    }
  };

  get timeInputValue() {
    const filtered = ['', '__:__'];
    return this.activeWarehouse.schedule.filter(
      (item) => !filtered.includes(item.time_from) || !filtered.includes(item.time_to),
    );
  }

  onHandleDayPickerChecked = (e) => {
    if (e.target.checked) {
      this.activeWarehouse.schedule[e.target.id - 1].time_from = '00:00';
      this.activeWarehouse.schedule[e.target.id - 1].time_to = '23:59';
    } else {
      this.activeWarehouse.schedule[e.target.id - 1].time_from = '';
      this.activeWarehouse.schedule[e.target.id - 1].time_to = '';
    }
    this.activeWarehouse.schedule[e.target.id - 1].checked = e.target.checked;
  };

  onHandleScheduleValues = (e) => {
    if (['time_from', 'time_to', 'is-checked-all-day'].includes(e.target.name)) {
      const timeFrom = this.activeWarehouse.schedule[e.target.id - 1].time_from;
      const timeTo = this.activeWarehouse.schedule[e.target.id - 1].time_to;
      const isCheckedAllDay = this.activeWarehouse.schedule[e.target.id - 1].checked;
      const isEqualMaskTimeFrom = /\d\d:\d\d/.test(timeFrom);
      const isEqualMaskTimeTo = /\d\d:\d\d/.test(timeTo);
      const isWrongOrderTime = timeFrom >= timeTo;
      this.statusSaveWarehouse.default();

      if (isEqualMaskTimeFrom && isEqualMaskTimeTo && isWrongOrderTime && !isCheckedAllDay) {
        this.activeWarehouse.schedule[e.target.id - 1].isOrderError = true;
      } else {
        this.activeWarehouse.schedule[e.target.id - 1].isOrderError = false;
      }
    }
  };

  get isWrongOrderTime() {
    return this.activeWarehouse.schedule.some((item) => item.isOrderError);
  }

  get normalizedSchedule() {
    const { schedule } = this.activeWarehouse;
    const result = [];
    schedule.forEach(({ time_from, time_to, day_of_week }) => {
      if (time_from || time_to) {
        result.push({ time_from: time_from, time_to: time_to, day_of_week: day_of_week });
      }
    });
    return result;
  }

  get normalizedActiveWarehouse() {
    const { schedule, ...rest } = this.activeWarehouse;
    const activeWarehouse = { ...rest, schedule: this.normalizedSchedule };
    return activeWarehouse;
  }

  changeTime = (field, value) => {
    // Добавляем 5 дней, если нет ни одного дня
    if (isEmpty(this.activeWarehouse.schedule)) {
      this.activeWarehouse.schedule = [1, 2, 3, 4, 5].map((dayOfWeek) => ({
        day_of_week: dayOfWeek,
        time_from: '',
        time_to: '',
        [field]: value,
      }));
    }
    // Пробегаемся по всем дням в расписании и меняем время
    this.activeWarehouse.schedule = this.activeWarehouse.schedule.map((day) => {
      const newDay = {
        ...day,
        [field]: value,
      };
      // Меняем местами если time_to < time_from
      // Оставил коммент на случай если решим вернуть фичу
      // if (
      //   newDay.time_from.match(/\d\d:\d\d/) &&
      //   newDay.time_to.match(/\d\d:\d\d/) &&
      //   newDay.time_from > newDay.time_to
      // ) {
      //   [newDay.time_from, newDay.time_to] = [newDay.time_to, newDay.time_from];
      // }
      return newDay;
    });
  };

  changeTwentyFourHours = (checked) => {
    if (checked) {
      this.changeTime('time_from', '00:00');
      this.changeTime('time_to', '23:59');
    } else {
      this.changeTime('time_from', '');
      this.changeTime('time_to', '');
    }
  };

  changeDay = (dayOfWeek) => {
    if (this.isSelectedDay(dayOfWeek)) {
      this.activeWarehouse.schedule = this.activeWarehouse.schedule.filter(
        (day) => String(day.day_of_week) !== String(dayOfWeek),
      );
    } else {
      this.activeWarehouse.schedule.push({
        day_of_week: dayOfWeek,
        time_from: this.displayTimeFrom,
        time_to: this.displayTimeTo,
      });
    }
  };

  onChangeField = (field, value, objFieldName) => {
    if (['name', 'address', 'city_id', 'sms_enabled'].includes(field)) {
      this.activeWarehouse[field] = value;
    }

    if (['contacts', 'schedule'].includes(field)) {
      this.activeWarehouse[field][0][objFieldName] = value;
    }
  };

  setWarehousePage = (value) => {
    this.isWarehousePage = value;
  };

  resetActiveWarehouse = () => {
    this.activeWarehouseID = 0;
    this.activeWarehouse = defaultWarehouse;
    this.statusSaveWarehouse.default();
  };

  setActiveWarehouse = () => {
    const newActiveWarehouse = this.warehouses.find(
      (item) => String(item.id) === String(this.activeWarehouseID),
    );
    if (newActiveWarehouse) {
      this.activeWarehouse = { ...newActiveWarehouse };
    }
  };

  clearMaskedInput = () => {
    this.activeWarehouse.contacts[0].phone = '';
  };

  setActiveWarehouseID = (id) => {
    this.activeWarehouseID = id;
  };

  getWarehouses = () => {
    this.statusGetWarehouses.request();
    getWarehousesAPI()
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.warehouses = data.result.warehouses.sort((a, b) => (a.id > b.id ? 1 : -1));
          this.statusGetWarehouses.success();
        });
      })
      .catch((error) => {
        this.statusGetWarehouses.failure();
        console.log(error);
      });
  };

  saveWarehouse = async (data) => {
    this.statusSaveWarehouse.request();
    return await postWarehouseAPI(data)
      .then(responseMiddleware)
      .then((res) => {
        this.statusSaveWarehouse.success();
        this.rootStore.notificationStore.pushNotification('Склад сохранён', 'success');
        return res?.result?.warehouse?.id;
      })
      .catch((error) => {
        this.statusSaveWarehouse.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при сохранении склада',
          'error',
        );
        console.log(error);
      });
  };

  deleteWarehouse = (id) => {
    this.statusDeleteWarehouse.request();
    deleteWarehouseAPI({ warehouse_ids: [id] })
      .then(responseMiddleware)
      .then(() => {
        this.statusDeleteWarehouse.success();
        this.rootStore.notificationStore.pushNotification('Склад удалён', 'success');
      })
      .catch((error) => {
        this.statusDeleteWarehouse.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при удалении склада',
          'error',
        );
        console.log(error);
      });
  };
}

export default WarehouseStore;
