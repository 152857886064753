import { autorun, makeAutoObservable, runInAction } from 'mobx';
import format from 'date-fns/format';
import postStatisticsAPI from '../services/postStatisticsAPI';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';

class StatisticsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {
      if (this.getStatisticsState.isSuccess) {
        this.updateStatistics();
      }
    });
  }

  labels = [''];
  data = {
    labels: this.labels,
    datasets: this.normalizedStatistics,
  };

  periodValue = { label: 'выбранный период', value: 'selected_period' };
  dateFrom = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  dateTo = new Date();
  timeValue = { label: 'дням', value: 'days' };

  getStatisticsState = new AsyncStateModel();
  statistics = [];
  idxStatuses = [];

  getStatistics = (statistics = {}) => {
    this.getStatisticsState.request();
    postStatisticsAPI(statistics)
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.getStatisticsState.success();
          this.statistics = data.result?.statistics;
        });
      })
      .catch((error) => {
        this.getStatisticsState.failure();
        console.log(`Error get statistics - ${error}`);
      });
  };

  getActiveStatistics = () => {
    const dateFrom = format(this.dateFrom, 'yyyy-MM-dd');
    const dateTo = format(this.dateTo, 'yyyy-MM-dd');

    this.getStatistics({
      warehouse_id: this.rootStore.warehouse.activeWarehouseID,
      report_type: 'average',
      timeslot_filters: { date_from: dateFrom, date_to: dateTo },
      exclude_staying_statuses: this.idxStatuses,
    });
  };

  convertStatisticSeconds = (seconds, labelValue) => {
    const normalizedSeconds = parseInt(seconds, 10);
    switch (labelValue) {
      case 'hours':
        return Math.floor(normalizedSeconds / 3600);
      case 'days':
        return Math.floor(normalizedSeconds / (3600 * 24));
      default:
        return '';
    }
  };

  get currentTimeValue() {
    return this.timeValue?.value;
  }

  get normalizedStatistics() {
    let result = [];
    this.statistics?.forEach((item) => {
      result.push({
        label: item?.staying_status_name,
        data: [item?.duration_seconds],
        backgroundColor: item?.color || 'rgb(89, 110, 129)',
      });
    });

    return result;
  }

  getIdxStatuses = (item) => {
    if (this.idxStatuses.includes(item)) {
      const filtered = this.idxStatuses.filter((i) => i !== item);
      this.idxStatuses = filtered;
    } else {
      this.idxStatuses = [...this.idxStatuses, item];
    }
  };

  updateStatistics = () => {
    this.data.datasets = this.normalizedStatistics;
  };

  showStatisticsData = (e) => {
    e.preventDefault();
    this.getActiveStatistics();
  };

  onChangePeriodValue = (selectInput, option) => {
    this[selectInput] = option;
  };

  onChangeDate = (selectDate, currentDatePicker) => {
    this[currentDatePicker] = new Date(selectDate);
  };
}

export default StatisticsStore;
