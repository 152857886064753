import React from 'react';
import ReactDOM from 'react-dom';
import App from '../src/App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './hooks/useStores';
import Store from './stores';

import './global.css';
import './index.css';

let container = undefined;

const interval = setInterval(() => {
  const newContainer = document.querySelector('div[class^="op-ati-docs-promo"]');
  if (newContainer && container !== newContainer) {
    // clearInterval(interval);
    ReactDOM.render(<App />, newContainer);
  }
}, 100);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
