async function postStatusesReorderAPI(warehouseId, reorderIdx) {
  const result = await window.fetch(
    `/webapi/timeslots/api/v1/staying_statuses/warehouse/${warehouseId}/change_order`,
    {
      method: 'POST',
      body: JSON.stringify(reorderIdx),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result;
}

export default postStatusesReorderAPI;
