import { configure } from 'mobx';
import ScheduleStore from './ScheduleStore';
import WarehouseStore from './WarehouseStore';
import PopupsStore from './PopupsStore';
import TerminalsStore from './TerminalsStore';
import BookingStore from './BookingStore';
import NotificationStore from './NotificationStore';
import ProfileStore from './ProfileStore';
import MyTimeslotsStore from './MyTimeslotsStore';
import TimeslotPopupStore from './TimeslotPopupStore';
import StayingStatusesStore from './StayingStatusesStore';
import TimeStepCalendarStore from './TimeStepCalendarStore';
import StatusesStore from './StatusesStore';
import StatisticsStore from './StatisticsStore';
configure({ enforceActions: 'observed', isolateGlobalState: true });

class RootStore {
  constructor() {
    this.scheduleStore = new ScheduleStore(this);
    this.warehouse = new WarehouseStore(this);
    this.popupsStore = new PopupsStore(this);
    this.timeslotPopupStore = new TimeslotPopupStore(this);
    this.stayingStatusesStore = new StayingStatusesStore(this);
    this.terminalsStore = new TerminalsStore(this);
    this.notificationStore = new NotificationStore(this);
    this.bookingStore = new BookingStore(this);
    this.profileStore = new ProfileStore();
    this.myTimeslotsStore = new MyTimeslotsStore();
    this.timeStepCalendarStore = new TimeStepCalendarStore();
    this.statusesStore = new StatusesStore(this);
    this.statisticsStore = new StatisticsStore(this);
  }
}

export default RootStore;
