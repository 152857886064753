import { makeAutoObservable } from 'mobx';

class AsyncStateModel {
  constructor(initialState = 'default') {
    this.state = initialState;
    makeAutoObservable(this);
  }

  default() {
    this.state = 'default';
  }

  request() {
    this.state = 'request';
  }

  success() {
    this.state = 'success';
  }

  failure() {
    this.state = 'failure';
  }

  get isDefault() {
    return this.state === 'default';
  }

  get isRequest() {
    return this.state === 'request';
  }

  get isSuccess() {
    return this.state === 'success';
  }

  get isFailure() {
    return this.state === 'failure';
  }
}

export default AsyncStateModel;
