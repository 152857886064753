const isSorted = (arr) => {
  let second_index;
  for (let first_index = 0; first_index < arr.length; first_index++) {
    second_index = first_index + 1;
    if (arr[second_index] - arr[first_index] < 0) return false;
  }
  return true;
};

export default isSorted;
