import { makeAutoObservable, when, autorun } from 'mobx';

const defaultStepValue = 15;

class TimeStepCalendarStore {
  constructor() {
    makeAutoObservable(this);
    when(() => {
      if ('stepValue' in localStorage) {
        const retrievedStepValue = localStorage.getItem('stepValue');
        this.stepValue = JSON.parse(retrievedStepValue);
      }
    });
    autorun(() => {
      if (this.stepValue.timeNumber !== defaultStepValue) {
        this.setToStorageStepValue();
      } else {
        this.removeFromStorageStepValue();
      }
    });
  }

  stepValue = {
    label: '15 минут',
    value: 1,
    timeNumber: defaultStepValue,
  };

  onSelectChange = (option) => {
    this.stepValue = option;
  };

  get stepValueNumber() {
    return this.stepValue.timeNumber;
  }

  setToStorageStepValue = () => {
    window.localStorage.setItem('stepValue', JSON.stringify(this.stepValue));
  };
  removeFromStorageStepValue = () => {
    window.localStorage.removeItem('stepValue');
  };
}

export default TimeStepCalendarStore;
