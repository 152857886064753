async function getStatusesAPI(id) {
  const result = await window.fetch(`/webapi/timeslots/api/v1/staying_statuses/warehouse/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
}

export default getStatusesAPI;
