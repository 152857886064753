const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

async function postWindowAPI(data) {
  const body = JSON.stringify(data);

  return await window
    .fetch('/webapi/timeslots/api/v1/timeslots', { ...requestOptions, body })
    .catch((error) => console.log('error', error));
}

export default postWindowAPI;
