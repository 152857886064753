import { makeAutoObservable } from 'mobx';

class NotificationStore {
  constructor() {
    makeAutoObservable(this);
  }

  notification = null;

  pushNotification = (text, type, delay = 2000, onCloseCallback) => {
    this.closeNotification();
    this.notification = {
      text,
      type,
      delay,
      callback: onCloseCallback,
    };
  };

  closeNotification = () => {
    if (typeof this.notification?.callback === 'function') {
      this.notification.callback();
    }
    this.notification = null;
  };
}

export default NotificationStore;
