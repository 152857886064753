async function getMyContactAPI() {
  return await window.fetch('/webapi/firms/v1.0/contact', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export default getMyContactAPI;
