import { makeAutoObservable } from 'mobx';
import getMytimeslotsAPI from '../services/getMytimeslotsAPI';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';

class MyTimeslotsStore {
  constructor() {
    makeAutoObservable(this);
  }

  myTimeslots = [];
  count = 0;
  slotValue = '';
  isActiveMySlots = false;
  statusGetMytimeslots = new AsyncStateModel();

  getMytimeslots = () => {
    this.statusGetMytimeslots.request();
    getMytimeslotsAPI()
      .then(responseMiddleware)
      .then((data) => {
        this.statusGetMytimeslots.success();
        this.myTimeslots = data.result.time_slots;
        this.count = data.result.count;
      })
      .catch((error) => {
        this.statusGetMytimeslots.failure();
        console.log(error);
      });
  };

  onCnangeSlotsValue = (value) => {
    this.slotValue = value;
  };

  setActiveMySlots = (value) => {
    this.isActiveMySlots = value;
  };
}

export default MyTimeslotsStore;
