async function postStatusAPI(newStatus) {
  const result = await window.fetch(`/webapi/timeslots/api/v1/staying_statuses`, {
    method: 'POST',
    body: JSON.stringify(newStatus),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
}

export default postStatusAPI;
