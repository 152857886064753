import { autorun, when, makeAutoObservable, runInAction } from 'mobx';
import { arrayMove } from 'react-movable';
import compact from 'lodash/compact';
import getStatusesAPI from '../services/getStatusesAPI';
import postStatusAPI from '../services/postStatusAPI';
import postStatusesReorderAPI from '../services/postStatusesReorderAPI';
import deleteStatusAPI from '../services/deleteStatusAPI';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';
import isSorted from '../utils/isSortedArr';

const listColors = [
  'rgb(175 98 98)',
  'rgb(92 122 145)',
  'rgb(203 133 175)',
  'rgb(190 165 118)',
  'rgb(121 174 181)',
  'rgb(134 177 211)',
  'rgb(161 150 202)',
  'rgb(207 128 128)',
  'rgb(128 116 176)',
  'rgb(137 194 178)',
];

class StatusesStore {
  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    when(() => {
      if (this.rootStore.warehouse.activeWarehouseID) {
        this.getStatuses(this.rootStore.warehouse.activeWarehouseID);
      }
    });
    autorun(() => {
      if (!isSorted(this.statusesPositions)) {
        this.postStatusesReorder(this.rootStore.warehouse.activeWarehouseID, {
          staying_status_ids: this.statusesIds,
        });
      }
    });
    autorun(() => {
      if (this.statusDelete.isSuccess) {
        this.getStatuses(this.rootStore.warehouse.activeWarehouseID);
        this.statusDelete.default();
      }
    });
    autorun(() => {
      if (this.statusPostNew.isSuccess) {
        this.updateStatusItems();
        this.statusPostNew.default();
      }
    });
  }

  // PROPERTIES
  statusItems = [];
  newStatusItem = {};
  countStatuses = 0;

  currentStatusId = 0;

  // STATES API CALLS
  statusGet = new AsyncStateModel();
  statusPostEdit = new AsyncStateModel();
  statusPostNew = new AsyncStateModel();
  statusPostReorder = new AsyncStateModel();
  statusDelete = new AsyncStateModel();

  /* API CALLS */
  getStatuses = (warehouseId) => {
    this.statusGet.request();
    getStatusesAPI(warehouseId)
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.statusGet.success();
          this.countStatuses = data.result?.count;
          this.statusItems = data.result?.staying_statuses;
        });
      })
      .catch((error) => {
        this.statusGet.failure();
        console.log(`Error get statuses - ${error}`);
      });
  };

  postEditStatus = (statusName, warehouseId, statusId) => {
    this.statusPostEdit.request();
    postStatusAPI({ status_name: statusName, warehouse: warehouseId, id: statusId })
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.statusPostEdit.success();
          this.newStatusItem = data.result?.staying_status;
        });
      })
      .catch((error) => {
        this.statusPostEdit.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при изменении статуса',
          'error',
        );
        console.log(`Error edit status - ${error}`);
      });
  };

  postNewStatus = (statusName, warehouseId, color) => {
    this.statusPostNew.request();
    postStatusAPI({ status_name: statusName, warehouse: warehouseId, color: color })
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.statusPostNew.success();
          this.newStatusItem = data.result?.staying_status;
        });
      })
      .catch((error) => {
        this.statusPostNew.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при добавлении статуса',
          'error',
        );
        console.log(`Error add status - ${error}`);
      });
  };

  postStatusesReorder = (warehouseId, reorderIdx) => {
    this.statusPostReorder.request();
    postStatusesReorderAPI(warehouseId, reorderIdx)
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.statusPostReorder.success();
          this.statusItems = data.result;
        });
      })
      .catch((error) => {
        this.statusPostReorder.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при изменении порядка статусов',
          'error',
        );
        console.log(`Error sort statuses - ${error}`);
      });
  };

  deleteStatus = (warehouseId, statusId) => {
    this.statusDelete.request();
    deleteStatusAPI(warehouseId, { staying_status_id: statusId })
      .then(responseMiddleware)
      .then((data) => {
        runInAction(() => {
          this.statusDelete.success();
          this.rootStore.notificationStore.pushNotification('Статус успешно удален', 'success');
          return data;
        });
      })
      .catch((error) => {
        this.statusDelete.failure();
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка удаления статуса',
          'error',
        );
        console.log(`Error delete status - ${error}`);
      });
  };
  /* API CALLS END */

  /* COMPUTED STATES */
  get statusesIds() {
    return this.statusItems.map((status) => status?.id);
  }

  get statusesPositions() {
    return this.statusItems.map((status) => status?.order_position);
  }

  get listStatusesColors() {
    const listColors = this.statusItems.map((status) => status?.color);
    return compact(listColors);
  }
  /* END COMPUTED STATES */

  setInitCurrentStatusId = () => {
    this.currentStatusId = 0;
  };

  getCurrentStatusId = (id) => {
    this.currentStatusId = id;
  };

  updateStatusItems = () => {
    this.statusItems = [...this.statusItems, this.newStatusItem];
  };

  setStatusItems = (oldStatusItem, newStatusItem) => {
    this.statusItems = arrayMove(this.statusItems, oldStatusItem, newStatusItem);
  };

  setEditInput = (status) => {
    const currentStatusIndex = this.statusItems.findIndex((item) => item.id === status.id);
    this.statusItems[currentStatusIndex] = {
      ...this.statusItems[currentStatusIndex],
      isEdit: true,
    };
  };

  getColor = () => {
    const filteredColors = listColors.filter((item) => !this.listStatusesColors.includes(item));
    const random = Math.floor(Math.random() * filteredColors.length);
    return filteredColors[random] || '#596e81';
  };

  addStatusItem = () => {
    this.postNewStatus('Новый статус', this.rootStore.warehouse.activeWarehouseID, this.getColor());
  };

  onSaveEditValue = (status) => {
    const currentStatusIndex = this.statusItems.findIndex((item) => item.id === status.id);
    if (this.statusItems[currentStatusIndex].status_name === '') return;
    this.statusItems[currentStatusIndex] = {
      ...this.statusItems[currentStatusIndex],
      isEdit: false,
    };
    this.postEditStatus(
      this.statusItems[currentStatusIndex].status_name,
      this.rootStore.warehouse.activeWarehouseID,
      this.statusItems[currentStatusIndex].id,
    );
  };

  onChangeCustomInput = (e, status) => {
    const currentStatus = this.statusItems.findIndex((item) => item.id === status.id);
    this.statusItems[currentStatus] = {
      ...this.statusItems[currentStatus],
      status_name: e.target.value.trimStart(),
    };
  };
}

export default StatusesStore;
