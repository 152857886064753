async function deleteStatusAPI(warehouseId, statusId) {
  const result = await window.fetch(
    `/webapi/timeslots/api/v1/staying_statuses/warehouse/${warehouseId}`,
    {
      method: 'DELETE',
      body: JSON.stringify(statusId),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result;
}

export default deleteStatusAPI;
