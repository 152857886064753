import { makeAutoObservable, reaction } from 'mobx';
import getTerminalAPI from '../services/getTerminalsAPI';
import postTerminalAPI from '../services/postTerminalAPI';
import deleteTerminalAPI from '../services/deleteTerminalAPI';
import AsyncStateModel from '../models/AsyncStateModel';
import responseMiddleware from '../middlewares/responseMiddleware';

const mapLoadingTypeValues = {
  any: 'Любая',
  gates: 'Ворота',
  up: 'Сверху',
  lateral: 'Боковая',
};

class TerminalsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    reaction(
      () => this.rootStore.warehouse.activeWarehouseID,
      () => this.getAllTerminals(),
    );
  }

  storeTerminals = [];
  currentTerminal = {};
  newTerminal = {};
  name = '';
  code = undefined;
  gates_count = undefined;
  loadingType = { label: 'Любая', value: 'any' };
  isEdit = false;

  statusGetTerminals = new AsyncStateModel();
  statusPostTerminal = new AsyncStateModel();
  statusDeleteTerminal = new AsyncStateModel();

  get currentTerminals() {
    return this.storeTerminals.filter(
      (item) => String(item.warehouse) === String(this.rootStore.warehouse.activeWarehouseID),
    );
  }

  get activeWarehouseTerminals() {
    return this.storeTerminals.filter(
      (terminal) =>
        String(terminal.warehouse) === String(this.rootStore.warehouse.activeWarehouseID),
    );
  }

  onChangeTerminalInput = (field, value) => {
    this[field] = value;
  };

  onChangeLoadingType = (option) => {
    this.loadingType = option;
  };

  setEditMode = (value) => {
    this.isEdit = value;
  };

  getCurrentTerminal = (terminal) => {
    this.currentTerminal = terminal;
  };

  setCurrentTerminal = () => {
    this.name = this.currentTerminal.name;
    this.gates_count = this.currentTerminal.gates_count;
    this.loadingType.label = mapLoadingTypeValues[this.currentTerminal?.loading_type];
  };

  resetCurrentTerminal = () => {
    this.name = '';
    this.code = undefined;
    this.gates_count = undefined;
    this.loadingType.label = 'Любая';
  };

  resetStatuses = () => {
    this.statusGetTerminals.default();
    this.statusPostTerminal.default();
    this.statusDeleteTerminal.default();
  };

  getAllTerminals = () => {
    this.statusGetTerminals.request();
    getTerminalAPI()
      .then(responseMiddleware)
      .then((data) => {
        this.statusGetTerminals.success();
        this.storeTerminals = data.result?.terminals;
      })
      .catch((error) => {
        this.statusGetTerminals.failure();
        console.log(error);
      });
  };

  addTerminal = (data) => {
    this.statusPostTerminal.request();
    postTerminalAPI(data)
      .then(responseMiddleware)
      .then((data) => {
        this.statusPostTerminal.success();
        this.newTerminal = data.result.terminal;
      })
      .catch((error) => {
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при сохранении терминала',
          'error',
        );
        this.statusPostTerminal.failure();
        console.log(error);
      });
  };

  deleteTerminal = (id) => {
    this.statusDeleteTerminal.request();
    deleteTerminalAPI({ terminal_ids: [id] })
      .then(responseMiddleware)
      .then(() => {
        this.statusDeleteTerminal.success();
      })
      .catch((error) => {
        this.rootStore.notificationStore.pushNotification(
          'Произошла ошибка при удалении терминала',
          'error',
        );
        this.statusDeleteTerminal.failure();
        console.log(error);
      });
  };
}

export default TerminalsStore;
