import { makeAutoObservable } from 'mobx';
import getMyContactAPI from '../services/getMyContactAPI';
import responseMiddleware from '../middlewares/responseMiddleware';
import AsyncStateModel from '../models/AsyncStateModel';

class ProfileStore {
  constructor() {
    makeAutoObservable(this);
  }

  contact = {};
  statusGetMyContacts = new AsyncStateModel();

  get isUser() {
    return !!window.atiUser.is_user;
  }

  getMyContact = () => {
    this.statusGetMyContacts.request();
    getMyContactAPI()
      .then(responseMiddleware)
      .then((res) => {
        this.contact = res;
        this.statusGetMyContacts.success();
      })
      .catch((error) => {
        this.statusGetMyContacts.failure();
        console.log(error);
      });
  };
}

export default ProfileStore;
